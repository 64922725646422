<template>
  <div>
    <h4 class="mt-4">
      {{ $getVisibleNames("manual.reporte", true, "Reportes") }}
    </h4>
    <div class="header-container mb-3">
      <div class="header-div">
        <b-form-group
          :label="`${$getVisibleNames(
            'manual.tipo_de_reporte',
            false,
            'Tipo de Reporte'
          )}`"
          label-cols="0"
          label-cols-sm="2"
          label-cols-md="2"
          label-cols-lg="2"
          label-cols-xl="2"
          label-size="sm"
          class="m-0"
        >
          <b-form-select
            :options="report_types"
            v-model="selected_report_type"
            size="sm"
            aria-describedby="select-report-feedback"
            :state="selected_report_type != null"
          ></b-form-select>
          <b-form-invalid-feedback id="select-report-feedback"
            >Debe seleccionar un
            {{
              $getVisibleNames(
                "manual.tipo_de_reporte",
                false,
                "Tipo de Reporte"
              )
            }}.</b-form-invalid-feedback
          >
        </b-form-group>
      </div>
    </div>

    <Filters
      :max_campuses="max_campuses"
      :max_careers="max_careers"
      :max_egress_profiles="max_egress_profiles"
      :max_sections="max_sections"
      :max_matters="max_matters"
      :max_modalities="max_modalities"
      :max_periods="max_periods"
      :max_levels="max_levels"
      :max_study_units="max_study_units"
      :max_cohorts="max_cohorts"
      :max_cycles="max_cycles"
      :load_cohorts="load_cohorts"
      :disabled="!selected_report_type"
      :required_selectors="required_selectors"
      :show_selectors="show_selectors"
      :clear_filters="clear_filters"
      @campuses="(x) => (campuses_ = x)"
      @careers="(x) => (careers_ = x)"
      @egress_profiles="(x) => (egress_profiles_ = x)"
      @levels="(x) => (levels_ = x)"
      @matters="(x) => (matters_ = x)"
      @periods="(x) => (periods_ = x)"
      @sections="(x) => (sections_ = x)"
      @study_units="(x) => (study_units_ = x)"
      @cohorts="(x) => (cohorts_ = x)"
      @cycles="(x) => (cycles_ = x)"
    >
    </Filters>
    <div class="toggle-div mt-3">
      <b-button
        class="load-report-btn"
        size="sm"
        @click="fetchReport"
        :disabled="!requirementFulfilled"
        >Cargar Reporte</b-button
      >
      <b-button
        class="load-report-btn ml-2"
        size="sm"
        @click="clear_filters = !clear_filters"
        >Limpiar Filtros</b-button
      >
    </div>
    <template v-if="!selected_report_type || (!chartdata && !loading)">
      <div class="reports-default">
        <div class="reports-default-caption">
          Seguimiento de resultados de aprendizaje.
        </div>
        <img
          class="reports-default-image"
          src="https://i.postimg.cc/rw8KnBf2/Pngtree-learning-progress-character-vector-5776835.png"
          alt="Responsive image"
        />
      </div>
    </template>
    <template v-if="selected_report_type == 1">
      <TableSkeleton
        v-if="loading && chartdata"
        :columns="chartdata ? chartdata.labels.length : null"
        :rows="chartdata ? chartdata.datasets.length : null"
        :table_props="{ bordered: true, striped: true }"
      ></TableSkeleton>
      <template v-else-if="report_key > 0 && chartdata">
        <MatterGradeInstrument
          :key="component_key + report_key"
          :chartdata="chartdata"
          :selectors="selectors"
        ></MatterGradeInstrument>
      </template>
    </template>
    <template v-if="selected_report_type == 2">
      <TableSkeleton
        v-if="loading"
        :table_props="{ bordered: true, striped: true }"
      ></TableSkeleton>
      <template v-else-if="report_key > 0 && chartdata">
        <template
          v-if="chartdata && chartdata.length > 0 && chartdata[0].datasets"
        >
          <MatterGradesPerSection
            :key="component_key + report_key"
            :chartdata="chartdata"
            :selectors="selectors"
          ></MatterGradesPerSection>
        </template>
        <template v-else>
          <div class="empty-dataset-alert">
            <b-icon icon="exclamation-circle" class="mr-1"></b-icon> No existen
            evaluaciones para los datos seleccionados.
          </div>
        </template>
      </template>
    </template>
    <template v-if="selected_report_type == 3">
      <TableSkeleton
        v-if="loading && chartdata"
        :columns="chartdata ? chartdata.labels.length : null"
        :rows="chartdata ? chartdata.datasets.length : null"
        :table_props="{ bordered: true, striped: true }"
      ></TableSkeleton>
      <template v-else-if="report_key > 0 && chartdata">
        <StudentGradesPerMatter
          :key="component_key + report_key"
          :chartdata="chartdata"
          :selectors="selectors"
        ></StudentGradesPerMatter>
      </template>
    </template>
    <template v-if="selected_report_type == 4">
      <TableSkeleton
        v-if="loading && chartdata"
        :columns="chartdata ? chartdata.labels.length : null"
        :rows="chartdata ? chartdata.datasets.length : null"
        :table_props="{ bordered: true, striped: true }"
      ></TableSkeleton>
      <template v-else-if="report_key > 0 && chartdata">
        <PlanLevelGradesPerStudent
          :key="component_key + report_key"
          :chartdata="chartdata"
          :selectors="selectors"
        ></PlanLevelGradesPerStudent>
      </template>
    </template>
    <template v-if="selected_report_type == 5">
      <TableSkeleton
        v-if="loading && chartdata"
        :columns="chartdata ? chartdata.labels.length : null"
        :rows="chartdata ? chartdata.datasets.length : null"
        :table_props="{ bordered: true, striped: true }"
      ></TableSkeleton>
      <template v-else-if="report_key > 0 && chartdata">
        <MatterGradesAvgSection
          :key="component_key + report_key"
          :chartdata="chartdata"
          :selectors="selectors"
        ></MatterGradesAvgSection>
      </template>
    </template>
    <template v-if="selected_report_type == 6">
      <TableSkeleton
        v-if="loading && chartdata"
        :columns="chartdata ? chartdata.labels.length : null"
        :rows="chartdata ? chartdata.datasets.length : null"
        :table_props="{ bordered: true, striped: true }"
      ></TableSkeleton>
      <template v-else-if="report_key > 0 && chartdata">
        <AssessmentMatterStudyUnits
          :key="component_key + report_key"
          :chartdata="chartdata"
          :selectors="selectors"
        ></AssessmentMatterStudyUnits>
      </template>
    </template>
    <template v-if="selected_report_type == 7">
      <TableSkeleton
        v-if="loading && chartdata"
        :columns="chartdata ? chartdata.labels.length : null"
        :rows="chartdata ? chartdata.datasets.length : null"
        :table_props="{ bordered: true, striped: true }"
      ></TableSkeleton>
      <template v-else-if="report_key > 0 && chartdata">
        <AssessmentMatter
          :key="component_key + report_key"
          :chartdata="chartdata"
          :selectors="selectors"
        ></AssessmentMatter>
      </template>
    </template>
    <template v-if="selected_report_type == 8">
      <TableSkeleton
        v-if="loading && chartdata"
        :columns="chartdata ? chartdata.labels.length : null"
        :rows="chartdata ? chartdata.datasets.length : null"
        :table_props="{ bordered: true, striped: true }"
      ></TableSkeleton>
      <template v-else-if="report_key > 0 && chartdata">
        <AssessmentStudyUnitsRanges
          :key="component_key + report_key"
          :chartdata="chartdata"
          :selectors="selectors"
        ></AssessmentStudyUnitsRanges>
      </template>
    </template>
    <template v-if="selected_report_type == 9">
      <TableSkeleton
        v-if="loading && chartdata"
        :columns="chartdata ? chartdata.labels.length : null"
        :rows="chartdata ? chartdata.datasets.length : null"
        :table_props="{ bordered: true, striped: true }"
      ></TableSkeleton>
      <template v-else-if="report_key > 0 && chartdata">
        <AssessmentMatterRanges
          :key="component_key + report_key"
          :chartdata="chartdata"
          :selectors="selectors"
        ></AssessmentMatterRanges>
      </template>
    </template>
    <template v-if="selected_report_type == 10">
      <TableSkeleton
        v-if="loading && chartdata"
        :columns="chartdata ? chartdata.labels.length : null"
        :rows="chartdata ? chartdata.datasets.length : null"
        :table_props="{ bordered: true, striped: true }"
      ></TableSkeleton>
      <template v-else-if="report_key > 0 && chartdata">
        <StudentCompetenceAchievement
          :key="component_key + report_key"
          :chartdata="chartdata"
          :selectors="selectors"
        ></StudentCompetenceAchievement>
      </template>
    </template>
    <template v-if="selected_report_type == 11">
      <TableSkeleton
        v-if="loading && chartdata"
        :columns="chartdata ? chartdata.labels.length : null"
        :rows="chartdata ? chartdata.datasets.length : null"
        :table_props="{ bordered: true, striped: true }"
      ></TableSkeleton>
      <template v-else-if="report_key > 0 && chartdata">
        <CompetenceAchievementRanges
          :key="component_key + report_key"
          :chartdata="chartdata"
          :selectors="selectors"
        ></CompetenceAchievementRanges>
      </template>
    </template>
  </div>
</template>

<script>
import * as names from "@/store/names";
import { mapGetters } from "vuex";

export default {
  name: "ReportsView",
  components: {
    Filters: () => import("./Filters"),
    MatterGradeInstrument: () =>
      import("./TableComponents/MatterGradeInstrument"),
    MatterGradesPerSection: () =>
      import("./TableComponents/MatterGradesPerSection"),
    StudentGradesPerMatter: () =>
      import("./TableComponents/StudentGradesPerMatter"),
    MatterGradesAvgSection: () =>
      import("./TableComponents/MatterGradesAvgSection"),
    AssessmentMatterStudyUnits: () =>
      import("./TableComponents/AssessmentMatterStudyUnits"),
    AssessmentMatter: () => import("./TableComponents/AssessmentMatter"),
    AssessmentStudyUnitsRanges: () =>
      import("./TableComponents/AssessmentStudyUnitsRanges"),
    AssessmentMatterRanges: () =>
      import("./TableComponents/AssessmentMatterRanges"),
    StudentCompetenceAchievement: () =>
      import("./TableComponents/StudentCompetenceAchievement"),
    CompetenceAchievementRanges: () =>
      import("./TableComponents/CompetenceAchievementRanges"),
    PlanLevelGradesPerStudent: () =>
      import("./TableComponents/PlanLevelGradesPerStudent"),
    TableSkeleton: () => import("@/components/reusable/TableSkeleton"),
  },
  data() {
    return {
      selected_report_type: null,
      max_campuses: "all",
      max_careers: "all",
      max_egress_profiles: "all",
      max_sections: "all",
      max_matters: "all",
      max_modalities: "all",
      max_periods: "all",
      max_levels: "all",
      max_study_units: "all",
      max_cohorts: "all",
      max_cycles: "all",
      campuses_: null,
      careers_: null,
      egress_profiles_: null,
      levels_: null,
      matters_: null,
      periods_: null,
      sections_: null,
      study_units_: null,
      cohorts_: null,
      cycles_: null,
      chartdata: null,
      component_key: 0,
      report_key: 0,
      selectors: null,
      clear_filters: false,
      disable_clear_btn: false,
      required_selectors:
        "campuses careers egress_profiles matters sections levels periods study_units cohorts cycles",
      show_selectors: "",
      loading: false,
      load_cohorts: false,
    };
  },
  computed: {
    ...mapGetters({}),
    title() {
      let title = "Reporte";
      if (this.selected_report_type)
        return this.report_types.find(
          (x) => x.value == this.selected_report_type
        ).text;
      return title;
    },
    requirementFulfilled() {
      if (
        this.selected_report_type == 1 ||
        this.selected_report_type == 5 ||
        this.selected_report_type == 10
      ) {
        if (!this.egress_profiles_ || !this.egress_profiles_.length)
          return false;
        if (!this.periods_ || !this.periods_.length) return false;
        if (!this.levels_ || !this.levels_.length) return false;
        return true;
      } else if (this.selected_report_type == 3) {
        if (!this.egress_profiles_ || !this.egress_profiles_.length)
          return false;
        if (!this.levels_ || !this.levels_.length) return false;
        if (!this.cohorts_ || !this.cohorts_.length) return false;
        return true;
      } else if (
        this.selected_report_type == 2 ||
        this.selected_report_type == 7
      ) {
        if (!this.sections_ || !this.sections_.length) return false;
        return true;
      } else if (
        this.selected_report_type == 6 ||
        this.selected_report_type == 8
      ) {
        if (!this.sections_ || !this.sections_.length) return false;
        if (!this.study_units_ || !this.study_units_.length) return false;
        return true;
      } else if (this.selected_report_type == 9) {
        if (!this.matters_ || !this.matters_.length) return false;
        return true;
      } else if (
        this.selected_report_type == 11 ||
        this.selected_report_type == 4
      ) {
        if (!this.egress_profiles_ || !this.egress_profiles_.length)
          return false;
        if (!this.cohorts_ || !this.cohorts_.length) return false;
        return true;
      }
      return false;
    },
    report_types() {
      return [
        {
          value: 1,
          text: `Reporte de Notas ${this.$getVisibleNames(
            "mesh.egressprofilematter",
            false,
            "Asignatura"
          )} por Instrumento`,
        },
        {
          value: 2,
          text: `Reporte de Notas ${this.$getVisibleNames(
            "mesh.egressprofilematter",
            false,
            "Asignatura"
          )} por ${this.$getVisibleNames(
            "teaching.section",
            false,
            "Sección"
          )}`,
        },
        {
          value: 3,
          text: `Reporte de Notas Estudiantes por ${this.$getVisibleNames(
            "mesh.egressprofilematter",
            false,
            "Asignatura"
          )}`,
        },
        {
          value: 4,
          text: `Reporte de Notas Estudiantes por Nivel`,
        },
        {
          value: 5,
          text: `Reporte de Notas Finales promedio ${this.$getVisibleNames(
            "mesh.egressprofilematter",
            true,
            "Asignaturas"
          )} por ${this.$getVisibleNames(
            "teaching.section",
            false,
            "Sección"
          )}`,
        },

        {
          value: 6,
          text: `Reporte de Assessment por ${this.$getVisibleNames(
            "mesh.egressprofilematter",
            false,
            "Asignatura"
          )} - Unidades`,
        },
        {
          value: 7,
          text: `Reporte de Assessment por ${this.$getVisibleNames(
            "mesh.egressprofilematter",
            false,
            "Asignatura"
          )}`,
        },
        {
          value: 8,
          text: "Reporte de Assessment por Rango (Unidades)",
        },
        {
          value: 9,
          text: `Reporte de Assessment por Rango (${this.$getVisibleNames(
            "mesh.egressprofilematter",
            false,
            "Asignatura"
          )})`,
        },
        {
          value: 10,
          text: `Reporte de Logros de ${String(
            this.$getVisibleNames("mesh.competence", true, "Competencias")
          )} por Estudiante`,
        },
        {
          value: 11,
          text: `Reporte de Logros de ${String(
            this.$getVisibleNames("mesh.competence", true, "Competencias")
          )} por Rango`,
        },
      ];
    },
  },
  methods: {
    resetSettings() {
      this.max_campuses = "all";
      this.max_careers = "all";
      this.max_egress_profiles = "all";
      this.max_sections = "all";
      this.max_matters = "all";
      this.max_modalities = "all";
      this.max_periods = "all";
      this.max_levels = "all";
      this.max_study_units = "all";
      this.max_cohorts = "all";
      this.max_cycles = "all";
    },
    fetchReport() {
      // this.loading = true;
      this.report_key = 0;
      this.selectors = {
        selected_campuses: this.campuses_,
        selected_careers: this.careers_,
        selected_egress_profiles: this.egress_profiles_,
        selected_levels: this.levels_,
        selected_matters: this.matters_,
        selected_periods: this.periods_,
        selected_sections: this.sections_,
        selected_study_units: this.study_units_,
        selected_cohorts: this.cohorts_,
        selected_cycles: this.cycles_,
      };
      if (this.selected_report_type == 1) {
        this.loading = true;
        this.fetchSectionGrades();
        setTimeout(() => {
          this.loading = false;
        }, 2000);
      } else if (this.selected_report_type == 2) {
        this.loading = true;
        this.fetchSingleSectionGrades();
        setTimeout(() => {
          this.loading = false;
        }, 2000);
      } else if (this.selected_report_type == 3) {
        this.loading = true;
        this.fetchMatterGradesPerStudent();
        setTimeout(() => {
          this.loading = false;
        }, 2000);
      } else if (this.selected_report_type == 4) {
        this.loading = true;
        this.fetchPlanLevelGradesPerStudent();
        setTimeout(() => {
          this.loading = false;
        }, 2000);
      } else if (this.selected_report_type == 5) {
        this.loading = true;
        this.fetchMatterGradesPerSection();
        setTimeout(() => {
          this.loading = false;
        }, 2000);
      } else if (this.selected_report_type == 6) {
        this.loading = true;
        this.fetchRAMicroPerSection();
        setTimeout(() => {
          this.loading = false;
        }, 2000);
      } else if (this.selected_report_type == 7) {
        this.loading = true;
        this.fetchRAMacroPerSection();
        setTimeout(() => {
          this.loading = false;
        }, 2000);
      } else if (this.selected_report_type == 8) {
        this.loading = true;
        this.fetchRAMicroRanges();
        setTimeout(() => {
          this.loading = false;
        }, 2000);
      } else if (this.selected_report_type == 9) {
        this.loading = true;
        this.fetchRAMacroRangesPerSection();
        setTimeout(() => {
          this.loading = false;
        }, 2000);
      } else if (this.selected_report_type == 10) {
        this.loading = true;
        this.fetchStudentCompetenceAchievement();
        setTimeout(() => {
          this.loading = false;
        }, 2000);
      } else if (this.selected_report_type == 11) {
        this.loading = true;
        this.fetchCompetenceAchievementRanges();
        setTimeout(() => {
          this.loading = false;
        }, 2000);
      }
    },

    fetchSectionGrades() {
      if (this.egress_profiles_ && this.periods_ && this.levels_) {
        this.$restful
          .Get(
            `/teaching/section_grades_report/?egress_profile=${this.egress_profiles_[0].id}&period=${this.periods_[0].id}&plan_level=${this.levels_[0].id}`
          )
          .then((response) => {
            this.report_key += 1;
            if (response) {
              this.chartdata = response;
            }
          });
      }
      setTimeout(() => {
        this.component_key += 1;
      }, 500);
    },
    fetchSingleSectionGrades() {
      if (this.sections_) {
        this.$restful
          .Get(
            `/teaching/one_section_grades_report/?section=${this.sections_[0].id}`
          )
          .then((response) => {
            this.report_key += 1;
            if (response) {
              this.chartdata = response;
            }
          });
      }
    },
    fetchMatterGradesPerStudent() {
      if (this.egress_profiles_ && this.levels_ && this.cohorts_) {
        this.$restful
          .Get(
            `/teaching/matter_grades_per_student/?egress_profile=${this.egress_profiles_[0].id}&plan_level=${this.levels_[0].id}&cohort=${this.cohorts_[0].cohort}`
          )
          .then((response) => {
            this.report_key += 1;
            if (response) {
              this.chartdata = response;
            }
          });
      }
    },
    fetchMatterGradesPerSection() {
      if (this.egress_profiles_ && this.periods_ && this.levels_) {
        this.$restful
          .Get(
            `/teaching/matter_grades_per_section/?egress_profile=${this.egress_profiles_[0].id}&period=${this.periods_[0].id}&plan_level=${this.levels_[0].id}`
          )
          .then((response) => {
            this.report_key += 1;
            if (response) {
              this.chartdata = response;
            }
          });
      }
    },
    fetchRAMicroPerSection() {
      if (this.sections_ && this.study_units_) {
        this.$restful
          .Get(
            `/teaching/ra_micro_and_ec_macro_per_section/?section=${this.sections_[0].id}&study_unit=${this.study_units_[0].id}`
          )
          .then((response) => {
            this.report_key += 1;
            if (response) {
              this.chartdata = response;
            }
          });
      }
    },
    fetchRAMacroPerSection() {
      if (this.sections_) {
        this.$restful
          .Get(
            `/teaching/ra_macro_per_section/?section=${this.sections_[0].id}`
          )
          .then((response) => {
            this.report_key += 1;
            if (response) {
              this.chartdata = response;
            }
          });
      }
    },
    fetchRAMicroRanges() {
      if (this.sections_) {
        this.$restful
          .Get(
            `/teaching/ra_micro_ranges_per_section_and_study_unit/?section=${this.sections_[0].id}&study_unit=${this.study_units_[0].id}`
          )
          .then((response) => {
            this.report_key += 1;
            if (response) {
              this.chartdata = response;
            }
          });
      }
    },
    fetchRAMacroRangesPerSection() {
      if (this.matters_ && this.sections_ && this.sections_.length == 0) {
        let arr = [];
        if (this.matters_ || this.matters_.length) {
          arr.push("egress_profile_matter=" + this.matters_[0].id);
        }
        if (this.periods_ || this.periods_.length) {
          arr.push("period=" + this.periods_[0].id);
        }
        let partial_url = "";
        if (arr.length > 0) partial_url = "?" + arr.join("&&");
        this.$restful
          .Get(
            `/teaching/ra_macro_ranges_per_section_or_egress_profile_matter/${partial_url}`
          )
          .then((response) => {
            this.report_key += 1;
            if (response) {
              this.chartdata = response;
            }
          });
      } else if (this.sections_) {
        this.$restful
          .Get(
            `/teaching/ra_macro_ranges_per_section_or_egress_profile_matter/?section=${this.sections_[0].id}`
          )
          .then((response) => {
            this.report_key += 1;
            if (response) {
              this.chartdata = response;
            }
          });
      }
    },
    fetchStudentCompetenceAchievement() {
      if (this.egress_profiles_ && this.periods_ && this.levels_) {
        this.$restful
          .Get(
            `/teaching/student_competence_achievement/?egress_profile=${this.egress_profiles_[0].id}&plan_level=${this.levels_[0].id}&period=${this.periods_[0].id}`
          )
          .then((response) => {
            this.report_key += 1;
            if (response) {
              this.chartdata = response;
            }
          });
      }
    },
    fetchCompetenceAchievementRanges() {
      if (
        (this.egress_profiles_ || this.egress_profiles_.length) &&
        (this.cohorts_ || this.cohorts_.length)
      ) {
        let arr = [];
        arr.push(
          "egress_profile=" + this.egress_profiles_[0].id,
          "cohort=" + this.cohorts_[0].cohort
        );
        if (this.cycles_ && this.cycles_.length) {
          arr.push("cycle=" + this.cycles_[0].id);
        }
        let partial_url = "";
        if (arr.length > 0) partial_url = "?" + arr.join("&&");

        this.$restful
          .Get(`/teaching/competence_achievement_report/${partial_url}`)
          .then((response) => {
            this.report_key += 1;
            if (response) {
              this.chartdata = response;
            }
          });
      }
    },
    fetchPlanLevelGradesPerStudent() {
      if (
        (this.egress_profiles_ || this.egress_profiles_.length) &&
        (this.cohorts_ || this.cohorts_.length)
      ) {
        let arr = [];
        arr.push(
          "egress_profile=" + this.egress_profiles_[0].id,
          "cohort=" + this.cohorts_[0].cohort
        );
        let partial_url = "";
        if (arr.length > 0) partial_url = "?" + arr.join("&&");

        this.$restful
          .Get(`/teaching/plan_level_grades_per_student/${partial_url}`)
          .then((response) => {
            this.report_key += 1;
            if (response) {
              this.chartdata = response;
            }
          });
      }
    },
    clearFilters() {
      this.clear_filters = true;
    },
    printingOrientationLandscape() {
      var css = "@media print {@page { size: A4 landscape; }}",
        head = document.head || document.getElementsByTagName("head")[0],
        style = document.createElement("style");
      style.type = "text/css";
      style.media = "print";

      if (style.styleSheet) {
        style.styleSheet.cssText = css;
      } else {
        style.appendChild(document.createTextNode(css));
      }
      head.appendChild(style);
    },
    printingOrientationPortrait() {
      var css = "@media print {@page { size: A4 portrait !important; }}",
        head = document.head || document.getElementsByTagName("head")[0],
        style = document.createElement("style");
      style.type = "text/css";
      style.media = "print";

      if (style.styleSheet) {
        style.styleSheet.cssText = css;
      } else {
        style.appendChild(document.createTextNode(css));
      }
      head.appendChild(style);
    },
  },
  watch: {
    selected_report_type() {
      // Ejemplo para mostrar todos los selectores como requeridos.
      // this.required_selectors = "campuses careers egress_profiles matters sections levels periods modalities";
      this.chartdata = null;
      this.resetSettings();
      if (this.selected_report_type == 1) {
        this.max_egress_profiles = 1;
        this.max_periods = 1;
        this.max_levels = 1;
        this.show_selectors = "campuses egress_profiles levels periods";
        this.required_selectors = "egress_profiles levels periods";
      } else if (this.selected_report_type == 2) {
        this.max_sections = 1;
        this.show_selectors =
          "campuses egress_profiles levels periods matters sections";
        this.required_selectors = "sections";
      } else if (this.selected_report_type == 3) {
        this.max_egress_profiles = 1;
        this.max_levels = 1;
        this.max_cohorts = 1;
        this.load_cohorts = true;
        this.show_selectors = "campuses egress_profiles levels cohorts";
        this.required_selectors = "egress_profiles levels cohorts";
      } else if (this.selected_report_type == 4) {
        this.max_egress_profiles = 1;
        this.max_cohorts = 1;
        this.load_cohorts = true;
        this.show_selectors = "campuses egress_profiles cohorts";
        this.required_selectors = "egress_profiles cohorts";
      } else if (this.selected_report_type == 5) {
        this.max_egress_profiles = 1;
        this.max_periods = 1;
        this.max_levels = 1;
        this.show_selectors = "campuses egress_profiles levels periods";
        this.required_selectors = "egress_profiles levels periods";
      } else if (this.selected_report_type == 6) {
        this.max_sections = 1;
        this.max_study_units = 1;
        this.show_selectors =
          "campuses egress_profiles levels periods matters sections study_units";
        this.required_selectors = "sections study_units";
      } else if (this.selected_report_type == 7) {
        this.max_sections = 1;
        this.show_selectors =
          "campuses egress_profiles levels periods matters sections";
        this.required_selectors = "sections";
      } else if (this.selected_report_type == 8) {
        this.max_sections = 1;
        this.max_study_units = 1;
        this.show_selectors =
          "campuses egress_profiles levels periods matters sections study_units";
        this.required_selectors = "sections study_units";
      } else if (this.selected_report_type == 9) {
        this.max_matters = 1;
        this.max_sections = 1;
        this.show_selectors =
          "campuses egress_profiles levels periods matters sections";
        this.required_selectors = "matters";
      } else if (this.selected_report_type == 10) {
        this.max_egress_profiles = 1;
        this.max_periods = 1;
        this.max_levels = 1;
        this.show_selectors = "campuses egress_profiles levels periods";
        this.required_selectors = "egress_profiles levels periods";
      } else if (this.selected_report_type == 11) {
        this.max_egress_profiles = 1;
        this.max_cohorts = 1;
        this.load_cohorts = true;
        this.show_selectors = "campuses egress_profiles cohorts cycles";
        this.required_selectors = "egress_profiles cohorts";
      }
    },
  },
  created() {
    this.$hasPermissions();
    this.$store.dispatch(names.FETCH_CAMPUSES);
    this.$store.dispatch(names.FETCH_FACULTIES);
    this.$store.dispatch(names.FETCH_CAREERS);
    this.$store.dispatch(names.FETCH_EGRESS_PROFILES);
    this.$store.dispatch(names.FETCH_MODALITIES);
    this.$store.dispatch(names.FETCH_PERIODS);
    this.$store.dispatch(names.FETCH_EGRESS_PROFILES_MATTERS);
    this.$store.dispatch(names.FETCH_CYCLES);
    // this.$store.dispatch(names.FETCH_SHIFTS);
    // if (!this.selected_report_type)
    //   setTimeout(() => {
    //     this.show_selectors =
    //       "campuses egress_profiles matters sections levels periods study_units cohorts cycles";
    //   }, 350);
  },
  mounted() {
    if (!this.selected_report_type)
      setTimeout(() => {
        this.show_selectors =
          "campuses egress_profiles matters sections levels periods study_units cohorts cycles";
        this.required_selectors = "";
      }, 350);
    this.printingOrientationLandscape();
  },
  beforeDestroy() {
    this.printingOrientationPortrait();
  },
};
</script>

<style scoped>
.load-report-btn {
  width: 10em !important;
  margin-bottom: 0.8em;
}
.required-remaining-icon {
  font-weight: bold;
  font-size: 9pt;
  margin-bottom: 0.5em;
  color: red;
}
.required-remaining-label {
  font-weight: bold;
}
.empty-dataset-alert {
  margin-top: 7%;
  font-size: 13pt;
}
/* Vista por defecto en reportes con ilustración */
.reports-default {
  display: flex;
  margin-left: auto;
  margin-right: 0;
  justify-content: center;
  margin-top: 5rem;
}
.reports-default-caption {
  font-size: 26pt;
  font-weight: bold;
  text-align: left;
  width: 260px;
  margin-top: 85px;
  color: var(--kl-primary-button-color);
  opacity: 0.6;
}
.reports-default-image {
  width: 350px;
  opacity: 0.5;
}
/* *********************************************** */

#select-report-feedback {
  margin-left: -10%;
}
@media (max-width: 575px) {
  #select-report-feedback {
    margin-left: 0;
  }
}
</style>